import * as React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { toast, Slide } from 'react-toastify';
import configAxios from '../service/configAxios';
import TextField from '@mui/material/TextField';


export default function CompanyContact() {
  const [email, setEmail] = React.useState('');


  function handleSubmit(event) {
    event.preventDefault();
    if (!email) {
      toast.warn('Por favor, preencha o seu email', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide
      });
      return;
    }
    const lead = {
      name: 'Site de vendas',
      email: email,
      subject: 'Captação através do site de vendas',
      message: 'Entrar em contato com o email',
      created_at: Date.now()
    };

    configAxios
      .post(`/sendEmail/contact`, lead)
      .then(() => {
        toast.success('Você solicitou com sucesso o nosso contato!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide
        });
        setEmail();
      })
      .catch(() => {
        toast.warn('Não foi possível solicitar o contato, por favor tente novamente!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide
        });
      });
  }

  function handleInputChange(event) {
    setEmail(event.target.value);
  }

  return (
    <Container id="CompanyContact" sx={{ mt: 4 }}>
      <Grid container spacing={6}>
        <Grid item xs={10} md={12}>
          <div>
            <Typography component="h2" variant="h2" color="text.primary">
              Phorm para empresas
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 2 }, mt: { xs: 2, sm: 4 }, fontSize: 18 }}>
              <p>Em um mundo onde o bem-estar e a saúde dos colaboradores são cada vez mais valorizados, as empresas que investem na qualidade de vida de seus funcionários estão um passo à frente. Apresentamos a você a plataforma que está revolucionando o universo fitness e transformando a maneira como as pessoas cuidam de sua mente e corpo.</p>
              {'\n'}
              {'\n'}
              <p>Por que fechar parceria conosco?</p>
              {'\n'}
              <p>Bem-estar integral: PHORM não se limita apenas ao condicionamento físico. Ela incorpora um programa completo de "Mind Fitness" para garantir que seus colaboradores estejam não apenas em forma, mas também mentalmente preparados para os desafios do dia a dia.</p>
              {'\n'}
              <p>Funcionários saudáveis e felizes são mais produtivos.</p>
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 2 }, mt: { xs: 2, sm: 2 }, fontSize: 18 }}>
              <p>Na Phorm, acreditamos que o sucesso começa com as parcerias certas. Envie seu e-mail e deixe que um dos nossos consultores entre em contato.</p>
              <p>Descubra como nossa plataforma integrada pode transformar a saúde e o bem-estar de seus colaboradores, trazendo mais motivação, produtividade e satisfação.</p>
              <p>Entre em contato e inspire-se!</p>
            </Typography>
            <div  style={{ "marginBottom": "5px"}}>
              <form
                style={{
                  alignSelf: 'center'
                }}
                onSubmit={handleSubmit}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  alignSelf="center"
                  spacing={1}
                  useFlexGap
                  sx={{ pt: 1, width: { xs: '100%', sm: 'auto' } }}>
                  <TextField
                    id="outlined-basic"
                    hiddenLabel
                    size="small"
                    variant="outlined"
                    aria-label="Digite o seu melhor e-mail"
                    placeholder="Digite o seu melhor e-mail"
                    onChange={(event) => handleInputChange(event)}
                    type="email"
                    value={email || ''}
                    required
                    inputProps={{
                      autoComplete: 'off',
                      'aria-label': 'Digite o seu email'
                    }}
                  />
                  <Button variant="outlined" color="primary" type="submit">
                    Solicitar contato
                  </Button>
                </Stack>
              </form>
            </div>
            <Typography variant="caption" sx={{ opacity: 0.8, mt: 2 }}>
              Ao clicar em &quot;Solicitar contato&quot; você autoriza que entremos em contato
              para realizarmos uma demonstração e prosseguirmos com essa parceria!
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
