import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 2, sm: 4 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 }
      }}>
      <Typography
        component="h2"
        variant="h2"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' }
        }}>
        Perguntas frequentes
      </Typography>
        <Box sx={{ 
          width: '100%', 
         }}>

        <Accordion 
          sx={{backgroundColor: '#2e2e2e'}}
          expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header">
            <Typography component="h3" variant="subtitle2">
            O que é a PHORM?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
            A PHORM é uma plataforma que conecta você a uma vasta rede de academias, salas de crossfit, profissionais de saúde (psicólogos, nutricionistas) e personal trainers.
            Oferecemos descontos exclusivos em produtos e serviços fitness. Nosso objetivo é proporcionar uma experiência integrada e personalizada de saúde e bem-estar, físico e mental.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{backgroundColor: '#2e2e2e' }}
        expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header">
            <Typography component="h3" variant="subtitle2">
            Como faço para me cadastrar na PHORM? Preciso ser funcionário de uma empresa para usar a plataforma?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
            O cadastro é simples e rápido. Basta baixar o aplicativo para IOS ou Android, inserir seu CPF e criar uma conta com um usuário e senha exclusivos. Você terá acesso imediato aos nossos serviços.
            A PHORM é para todos! Qualquer pessoa pode se cadastrar e usufruir dos benefícios do aplicativo, independentemente de onde trabalha.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{backgroundColor: '#2e2e2e'}} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header">
            <Typography component="h3" variant="subtitle2">
            Quais são os planos oferecidos pela PHORM? Como funciona o acesso às academias e parceiros?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
            Oferecemos diversos planos que se adequam a diferentes estilos de vida e objetivos. Você pode escolher o plano que melhor combina com você e aproveitar os serviços oferecidos pelo aplicativo.
            Com o plano ativo, você pode fazer check-in diretamente nas academias parceiras pelo aplicativo. Algumas academias podem exigir agendamento prévio, então é importante verificar antes de ir.
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion sx={{backgroundColor: '#2e2e2e'}} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header">
            <Typography component="h3" variant="subtitle2">
            Quais profissionais de saúde estão disponíveis na PHORM? O que devo fazer se precisar de suporte?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
            Conectamos você com nutricionistas, psicólogos, terapeutas mindfulness e personal trainers, todos disponíveis para ajudá-lo a atingir seus objetivos de saúde e bem-estar.
            Nosso suporte está sempre disponível para ajudar. Você pode entrar em contato via chat no aplicativo para resolver dúvidas e problemas.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{backgroundColor: '#2e2e2e'}} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header">
            <Typography component="h3" variant="subtitle2">
            O que mais a PHORM oferece além de acesso a academias e profissionais de saúde? Como acompanho minha evolução?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
            Além do acesso a academias e profissionais de saúde, a PHORM oferece descontos exclusivos em suplementos, produtos e serviços fitness. Tudo o que você precisa para um estilo de vida saudável está ao seu alcance.
            No aplicativo, você pode registrar treinos e acompanhar sua evolução, receber orientação personalizada e ajustar suas metas conforme avança.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{backgroundColor: '#2e2e2e'}} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header">
            <Typography component="h3" variant="subtitle2">
            Posso usar a PHORM para localizar academias, profissionais de saúde, produtos e serviços fitness?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
            Sim! A PHORM possui uma vasta rede de parceiros, basta consultar o nosso aplicativo e através de georreferenciamento localizar a academia ou serviço fitness mais próximo de você.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{backgroundColor: '#2e2e2e'}} expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header">
            <Typography component="h3" variant="subtitle2">
            Existe algum custo adicional para usar a PHORM? Posso cancelar meu plano a qualquer momento?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
            Não há taxas ocultas. Os custos estão inclusos no valor mensal do plano que você escolher. 
            Você pode cancelar seu plano a qualquer momento diretamente pelo aplicativo.
            </Typography>
          </AccordionDetails>
        </Accordion>

      </Box>
    </Container>
  );
}
