/* eslint-disable no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';

import ToggleColorMode from '../components/TooggleColorMode';

import logoLight from '../assets/images/static/logo-light.png';
import logoDark from '../assets/images/static/logo-dark.png';

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);
  const [urlPlatform] = React.useState('https://app.phorm.com.br/');

  const logoStyle = {
    width: mode === 'light' ? '150px' : '68px',
    height: 'auto',
    cursor: 'pointer'
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth'
      });
      setOpen(false);
    }
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2
        }}>
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '10px',
              bgcolor:
                theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)'
            })}>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '',
                px: 0
              }}>
              <img
                src={mode === 'light' ? logoDark : logoLight}
                style={logoStyle}
                alt="Phorm Fitness"
              />
              <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }}>
                <MenuItem
                  onClick={() => scrollToSection('AboutPlatform')}
                  sx={{ py: '4px', px: '12px' }}>
                  <Typography variant="body2" color="text.primary">
                    Conheça a phorm
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('AboutUS')}
                  sx={{ py: '4px', px: '12px' }}>
                  <Typography variant="body2" color="text.primary">
                    Conheça a nossa história
                  </Typography>
                </MenuItem>

                <MenuItem onClick={() => scrollToSection('faq')} sx={{ py: '6px', px: '12px' }}>
                  <Typography variant="body2" color="text.primary">
                    FAQ
                  </Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => scrollToSection('CompanyContact')}
                  sx={{ py: '4px', px: '12px' }}>
                  <Typography variant="body2" color="text.primary">
                    Empresas
                  </Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => scrollToSection('PartnersContact')}
                  sx={{ py: '4px', px: '12px' }}>
                  <Typography variant="body2" color="text.primary">
                    Torne-se um parceiro
                  </Typography>
                </MenuItem>
                {/* <MenuItem
                  onClick={() => scrollToSection('features')}
                  sx={{ py: '4px', px: '12px' }}>
                  <Typography variant="body2" color="text.primary">
                    Rede Parceira
                  </Typography>
                </MenuItem> */}
                {/* <MenuItem onClick={() => scrollToSection('hero')} sx={{ py: '6px', px: '12px' }}>
                  <Typography variant="body2" color="text.primary">
                    Entre em contato
                  </Typography>
                </MenuItem> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center'
              }}>
              <Button
                style={{ backgroundColor: '#A5E13B', fontWeight: 500, color: '#131313'  }}
                variant="outlined"
                size="small"
                component="a"
                sx={{ ml: 2 }}
                href={'https://app.phorm.com.br/'}
                target="_blank">
                <PersonIcon style={{ color: '#131313' }} sx={{ mr: 1 }} />
                Acessar a plataforma
              </Button>
            </Box>

            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}>
                <MenuIcon />
              </Button>
              
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: '#2e2e2e',
                    flexGrow: 1,
                  }}>
                  <MenuItem 
                    style={{ color: '#FFF' }}
                    onClick={() => scrollToSection('AboutPlatform')}>
                      Conheça a Phorm
                  </MenuItem>

                  <MenuItem
                    style={{ color: '#FFF' }}
                    onClick={() => scrollToSection('AboutUS')}>
                      Conheça a nossa história
                  </MenuItem>

                  <MenuItem
                    style={{ color: '#FFF' }}
                    onClick={() => scrollToSection('faq')}>
                      FAQ
                  </MenuItem>

                  <MenuItem
                    style={{ color: '#FFF' }}
                    onClick={() => scrollToSection('CompanyContact')}>
                      Empresas
                  </MenuItem>

                  <MenuItem
                    style={{ color: '#FFF' }}
                    onClick={() => scrollToSection('PartnersContact')}>
                      Torne-se um parceiro
                  </MenuItem>
     
                  {/* <MenuItem
                    style={{ color: '#FFF' }}
                    onClick={() => scrollToSection('ContactUS')}>
                      Contate-nos
                  </MenuItem> */}

                  <Divider />
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      component="a"
                      href={urlPlatform}
                      target="_blank"
                      sx={{ width: '100%' }}>
                      <PersonIcon sx={{ mr: 1 }} />
                      Acessar a plataforma
                    </Button>
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired
};

export default AppAppBar;
