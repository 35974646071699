import axios from 'axios';

const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://app.vendasplanoonline.com.br:3014'
    : 'http://localhost:3014';

const configAxios = axios.create({
  baseURL
});

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  throw error;
};

configAxios.interceptors.response.use(
  (response) => {
    return responseHandler(response);
  },
  (error) => {
    errorHandler(error);
  }
);

export default configAxios;
