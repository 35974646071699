/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import {
  Snackbar,
  Fade,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import AppAppBar from "../layouts/AppAppBar";
import Footer from "../layouts/Footer";

import AboutPlatform from "../components/AboutPlatform";
import AboutUS from "../components/AboutUS";
import CompanyContact from "../components/CompanyContact";
import PartnersContact from "../components/PartnersContact";
import OursPartners from "../components/OursPartners";
// import ContactUS from '../components/ContactUS';
import FAQ from "../components/FAQ";
import defaultTheme from "../themes";

import background from "../assets/images/components/Background_wallpaper.png";
import imageApple from "../assets/images/static/img-apple.png";
import imgGoogle from "../assets/images/static/img-google.png";

const LandingPage = () => {
  const [mode, setMode] = React.useState("light");
  const LPtheme = createTheme(defaultTheme(mode));
  const [privacyOpen, setPrivacyOpen] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(true);
  const handleCloseModal = () => setOpenModal(false);

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setPrivacyOpen(false);
  };

  const action = (
    <>
      <Button
        style={{ backgroundColor: "#A5E13B", color: "#000", fontWeight: 600 }}
        variant="outlined"
        size="small"
        onClick={handleClose}
      >
        Prosseguir
      </Button>
    </>
  );

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} success />
      <Snackbar
        open={privacyOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={10000}
        TransitionComponent={Fade}
        onClose={handleClose}
        message={`Sua privacidade é importante é para nós e por isso, não rastreamos ou guardamos as suas informações!`}
        action={action}
        ContentProps={{
          sx: {
            color: "black",
            borderRadius: "10px",
            fontWeight: "700",
          },
        }}
      />

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: 15 }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "#171717", fontWeight: 600 }}
        >
          {"Prezado(a) associado"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#171717", mb: 2, fontWeight: 600 }}
          >
            Para acessar a nossa rede credenciada e aproveitar todos os
            beneficíos, faça o download do nosso aplicativo. Disponível para
            Android e IOs.
          </DialogContentText>
          <a href="https://play.google.com/store/apps/details?id=br.com.phorm" target="_blank" rel="noopener noreferrer">
            <img src={imgGoogle} width="172" />
          </a>
          <img src={imageApple} width="169" />
        </DialogContent>
        <DialogActions sx={{ mt: -3 }}>
          <Button
            onClick={handleCloseModal}
            autoFocus
            style={{
              backgroundColor: "#A5E13B",
              color: "#000",
              fontWeight: 600,
            }}
          >
            Fechar e prosseguir
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ backgroundImage: `url(${background})` }}>
        <AboutPlatform />
        <AboutUS />
        <OursPartners />
        <FAQ />
        <CompanyContact />
        <PartnersContact />
        {/* <ContactUS /> */}
        <Footer mode={mode} />
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;
