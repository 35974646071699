import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function Features() {
  return (
    <Container id="AboutUS" sx={{ pt: { xs: 4, sm: 8 } }}>
      <Grid container spacing={6}>
        <Grid item xs={10} md={12}>
          <div>
            <Typography component="h2" variant="h2" color="text.primary">
            Quem somos
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 2 }, mt: { xs: 2, sm: 4 }, fontSize: 18 }}>
                <p>Bem-vindo à Phorm, a plataforma que conecta pessoas e empresas a uma vasta rede de academias, salas de crossfit, profissionais de saúde, e muito mais. Nossa missão é proporcionar uma experiência integrada e personalizada de saúde e bem-estar, oferecendo descontos exclusivos em produtos e serviços fitness de qualidade.</p>
                {'\n'}
                <p>A Phorm foi idealizada por três profissionais com vasta experiência em suas áreas: dois engenheiros de tecnologia da informação e um jornalista especialista na área da comunicação de massa. Juntos, eles criaram um modelo de parcerias inovador para facilitar o acesso de todos a produtos e serviços fitness, além de programas de treinamento e bem-estar. </p>
                {'\n'}
                <p>Nosso objetivo é ser a melhor plataforma do gênero no Brasil, destacando-se pela facilidade de uso, sempre com preços atrativos.</p>
                {'\n'}
                <p>Na Phorm, acreditamos que a saúde e o bem-estar devem estar ao alcance de todos. Por isso, oferecemos um serviço de fácil aplicação, permitindo que qualquer pessoa, independentemente do nível de condicionamento físico, possa melhorar sua qualidade de vida com praticidade e economia.</p>
                {'\n'}
                <p>Descubra como a Phorm pode transformar sua vida.  Conecte-se conosco e aproveite uma experiência única, feita especialmente para você.</p>
                {'\n'}
                <p>Junte-se a nós e seja parte da revolução fitness no Brasil. Phorm - Saúde para todos.</p>
            </Typography>
          </div>
        </Grid>

      </Grid>
    </Container>
  );
}
