import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Goldbox from "../assets/images/static/Goldbox.png";
import One from "../assets/images/static/One.png";
import Drudi from "../assets/images/static/Drudi.png";
import Fenix from "../assets/images/static/Fenix.png";
import Garra from "../assets/images/static/Garra.png";
import Power from "../assets/images/static/Power.png";
import LF from "../assets/images/static/LF.png";
import mxlife from "../assets/images/static/MXLife.png";

export default function OursPartners() {
  const settings = {
    infinite: true, // Permite looping infinito
    speed: 1500, // Tempo da transição (ajuste para deixar mais rápido)
    slidesToShow: 5, // Número de imagens visíveis ao mesmo tempo
    slidesToScroll: 1, // Quantidade de imagens que avançam por vez
    autoplay: true, // Ativar autoplay
    autoplaySpeed: 1, // Remove o delay entre transições
    cssEase: "linear", // Faz o efeito contínuo
    arrows: false, // Esconde setas de navegação
    dots: false, // Remove os indicadores
    centerMode: true, // Ativa o efeito de espaçamento
    centerPadding: "10px", // Adiciona espaço entre os slides
    responsive: [
      {
        breakpoint: 768, // Ajustes para telas menores que 768px
        settings: {
          slidesToShow: 2, // No mobile, apenas 1 imagem por vez
          centerPadding: "20px", // Aumenta o espaçamento entre os slides
        },
      },
      {
        breakpoint: 1024, // Ajustes para tablets
        settings: {
          slidesToShow: 2,
          centerPadding: "15px",
        },
      },
    ],
  };

  return (
    <Container id="AboutUS" sx={{ pt: { xs: 4, sm: 8 } }}>
      <Grid container spacing={6}>
        <Grid item xs={10} md={12}>
          <Typography component="h2" variant="h2" mb={1} color="text.primary">
            Nossos parceiros
          </Typography>
          <Slider {...settings} className="w-full h-64">
            <div>
              <img
                src={One}
                alt="One Gym"
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <img
                src={Goldbox}
                alt="Goldbox Gym"
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <img
                src={Drudi}
                alt="Drudi Gym"
                className="w-full h-full object-cover"
              />
            </div>

            <div>
              <img
                src={Fenix}
                alt="Fenix Gym"
                className="w-full h-full object-cover"
              />
            </div>

            <div>
              <img
                src={Garra}
                alt="Garra Gym"
                className="w-full h-full object-cover"
              />
            </div>

            <div>
              <img
                src={Power}
                alt="Power Gym"
                className="w-full h-full object-cover"
              />
            </div>

            <div>
              <img
                src={LF}
                alt="LF Gym"
                className="w-full h-full object-cover"
              />
            </div>

            <div>
              <img
                src={mxlife}
                alt="mxlife Gym"
                className="w-full h-full object-cover"
              />
            </div>
          </Slider>
        </Grid>
      </Grid>
    </Container>
  );
}
